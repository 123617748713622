import React from 'react';
import styles from './Select.module.scss';

const BaseSelect = (props) => {
  const {
    name,
    disabled = false,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClick,
    value,
    options = [],
    placeholder,
  } = props;

  return (
    <select
      disabled={disabled}
      value={value}
      name={name}
      className={styles.select}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onClick={onClick}
      {...props}
    >
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default BaseSelect;
