import styles from './InstalmentButtons.module.scss'
import tbIcon from '../../../../icons/tbIcon.svg'
import BaseSelect from '../../../../uikit/Select'
import Loader from '../FormButton/Loader'
import { useSelector } from 'react-redux'
import React from 'react'

const InstalmentButtons = ({
  isLoading,
  handleClickInstalment,
  btnDisabled,
  instalmentRRId,
  instalmentTBankId,
  duration,
  setDuration
}) => {
  const result = useSelector((state) => state.result)

  const payment_method = result?.frame_companies?.find((el) => el)?.company_info
    .payment_methods

  const durationData = payment_method?.find(
    (el) => el.merchant_type === 'instalment_tinkoff'
  )?.duration

  return (
    <div data-testid="instalment-wrapper" className={styles.wrapper}>
      <p>Оформить рассрочку</p>
      {instalmentTBankId && (
        <>
          <div data-testid="instalment-select-box" className={styles.selectBox}>
            <label
              data-testid="select-label"
              id="select-label"
              htmlFor="select"
            >
              Количество месяцев для погашения:
            </label>
            <BaseSelect
              value={duration}
              id="select"
              onChange={(e) => setDuration(e.target.value)}
              placeholder="Выберите период"
              options={[
                { value: '', label: 'Выберите период' },
                ...durationData?.map((item) => ({
                  value: item,
                  label: `${item} месяцев`
                }))
              ]}
              data-testid="instalment-select"
            />
          </div>
          <button
            data-testid="instalment-tbank-button"
            type="button"
            onClick={(e) => handleClickInstalment(e, instalmentTBankId)}
            disabled={btnDisabled || !duration}
            className={styles.tBankButton}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <img src={tbIcon} alt="t-bank" />
                <span>Оформить рассрочку</span>
              </>
            )}
          </button>
        </>
      )}

      {instalmentRRId && (
        <button
          data-testid="instalment-rr-button"
          type="button"
          onClick={(e) => handleClickInstalment(e, instalmentRRId)}
          disabled={btnDisabled}
          className={styles.RRbutton}
        >
          {isLoading ? <Loader primary /> : 'Рассрочка для граждан СНГ'}
        </button>
      )}
    </div>
  )
}

export default InstalmentButtons
