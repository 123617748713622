import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './PayMethods.module.scss'

function PayMethods({
  result,
  payment_method_id,
  setPayment_method_id,
  payMethodId,
  setNoIframe,
  cloud,
  setCloud,
  promoRes,
  setCurrency,
  setPmidForPaytabs,
  instalmentRRId,
  setInstalmentRRId,
  setHasNoPayMethod,
  instalmentTBankId,
  setInstalmentTBankId
}) {
  const [rf, setRf] = useState(true)
  const [eng, setEng] = useState(false)
  const [tinkoff_sbp, setTinkoff_sbp] = useState(0)
  const [tinkoff, setTinkoff] = useState(0)
  const [prodamus, setProdamus] = useState(0)
  const [paytabs, setPaytabs] = useState(0)
  const [terpay, setTerpay] = useState(0)
  const [moneta, setMoneta] = useState(0)
  const [moneta_v2, setMoneta_v2] = useState(0)
  const [disBtns, setDisBtns] = useState(false)

  const recStatus = result?.recurrent_status ?? false
  const payment_method = result?.frame_companies?.find((el) => el)?.company_info
    .payment_methods

  const location = useLocation()
  const url = window.location.href
  const urlOrgin = new URL(url).origin
  const gate = new URLSearchParams(location.search).get('gate')

  useEffect(() => {
    //по дефолту выбирается карта
    if ((cloud || tinkoff || moneta_v2) && gate !== '1' && !payMethodId) {
      setPayment_method_id(cloud || tinkoff || moneta_v2)
    }
  }, [cloud, gate, payMethodId, tinkoff, moneta_v2])

  const getRandomPayId = (arr) => {
    //когда несколько одинаковых типов платежных методов для рандомного распределения
    if (arr.length) {
      const randomIndex = Math.floor(Math.random() * arr.length)
      const randomElement = arr[randomIndex]
      return randomElement.id
    } else {
      return 0
    }
  }

  useEffect(() => {
    //распределение платежных методов

    // массивы нужны на случай нескольких одинаковых типов платежных методов
    const cardArr = []
    const prodamusArr = []
    const tinkoff_sbpArr = []
    const tinkoffArr = []
    const paytabsArr = []
    const terpayArr = []
    const monetaArr = []
    const moneta_v2Arr = []

    payment_method?.map((el) => {
      if (el.merchant_type === 'cloudpayments') {
        cardArr.push(el)
        setCloud(getRandomPayId(cardArr))
        recStatus === true && setPayment_method_id(getRandomPayId(cardArr))
        gate === '1' &&
          recStatus === true &&
          setPayment_method_id(getRandomPayId(cardArr))
      } else if (el.merchant_type === 'tinkoff') {
        tinkoffArr.push(el)
        setTinkoff(getRandomPayId(tinkoffArr))
      } else if (el.merchant_type === 'prodamus') {
        prodamusArr.push(el)
        setProdamus(getRandomPayId(prodamusArr))
        gate === '1' &&
          recStatus === false &&
          setPayment_method_id(getRandomPayId(prodamusArr))
      } else if (el.merchant_type === 'tinkoff_sbp') {
        tinkoff_sbpArr.push(el)
        setTinkoff_sbp(getRandomPayId(tinkoff_sbpArr))
      } else if (el.merchant_type === 'paytabs') {
        paytabsArr.push(el)
        setPaytabs(getRandomPayId(paytabsArr))
        setPmidForPaytabs(el.id)
        gate === '1' &&
          recStatus === false &&
          setPayment_method_id(getRandomPayId(paytabsArr))
      } else if (el.merchant_type === 'terpay') {
        terpayArr.push(el)
        setTerpay(getRandomPayId(terpayArr))
      } else if (el.merchant_type === 'moneta') {
        monetaArr.push(el)
        setMoneta(getRandomPayId(monetaArr))
      } else if (el.merchant_type === 'moneta_v2') {
        moneta_v2Arr.push(el)
        setMoneta_v2(getRandomPayId(moneta_v2Arr))
      } else if (el.merchant_type === 'rr') {
        setInstalmentRRId(el.id)
      } else if (el.merchant_type === 'instalment_tinkoff') {
        setInstalmentTBankId(el.id)
      }
    })
  }, [payment_method, recStatus, gate])

  useEffect(() => {
    //валюта платежного метода
    if (payment_method?.length && payment_method_id) {
      const filtered = payment_method?.filter(
        (item) => item.id === payment_method_id
      )
      const obj = filtered[0]
      setCurrency(obj?.currency)
    }
  }, [payment_method, payment_method_id])

  useEffect(() => {
    if (payment_method?.length === 1) {
      // если у компании один метод оплаты
      setDisBtns(true)
      setPayment_method_id(payment_method?.[0].id)

      if (payment_method?.[0].is_instalment) {
        setHasNoPayMethod(true)
      }
    } else if (payment_method?.length === 2) {
      // если у компании два метода оплаты и один из них рассрочка
      const instalmentMethod = payment_method.find(
        (method) => method.is_instalment
      )
      if (instalmentMethod) {
        setDisBtns(true)
        const nonInstalmentMethod = payment_method.find(
          (method) => !method.is_instalment
        )
        if (nonInstalmentMethod) {
          setPayment_method_id(nonInstalmentMethod.id)
        }
      }
    }
  }, [payment_method])

  useEffect(() => {
    //определяет как будет открываться страница оплаты

    if (
      payment_method_id === tinkoff_sbp ||
      payment_method_id === paytabs ||
      payment_method_id === terpay ||
      payment_method_id === moneta_v2 ||
      payment_method_id === instalmentRRId ||
      payment_method_id === instalmentTBankId
    ) {
      //редирект
      setNoIframe(false)
    } else {
      setNoIframe(true)
    }
  }, [
    payment_method_id,
    tinkoff_sbp,
    paytabs,
    terpay,
    moneta_v2,
    instalmentRRId,
    instalmentTBankId
  ])

  useEffect(() => {
    //если в параметрах передали pm_id
    if (payMethodId) {
      setPayment_method_id(+payMethodId)
    }
  }, [payMethodId])

  useEffect(() => {
    //РФ или Иностранная при загрузке страницы
    if (payment_method_id) {
      if (
        payment_method_id === paytabs ||
        payment_method_id === prodamus ||
        urlOrgin === process.env.REACT_APP_PROD_AE_URL ||
        urlOrgin === process.env.REACT_APP_TEST_AE_URL
      ) {
        setRf(false)
        setEng(true)
      } else if (
        payment_method_id === cloud ||
        payment_method_id === tinkoff_sbp ||
        payment_method_id === tinkoff ||
        payment_method_id === moneta_v2 ||
        urlOrgin === process.env.REACT_APP_PROD_RU_URL ||
        urlOrgin === process.env.REACT_APP_TEST_RU_URL
      ) {
        setRf(true)
        setEng(false)
      }
    }
  }, [
    payment_method_id,
    paytabs,
    prodamus,
    cloud,
    tinkoff_sbp,
    urlOrgin,
    tinkoff,
    moneta_v2
  ])

  useEffect(() => {
    //редирект для paytabs
    if (paytabs) {
      if (
        payment_method_id === paytabs ||
        +payMethodId === paytabs ||
        gate === '1'
      ) {
        if (urlOrgin === process.env.REACT_APP_PROD_RU_URL) {
          const newUrl = url.replace(
            process.env.REACT_APP_PROD_RU_URL,
            process.env.REACT_APP_PROD_AE_URL
          )
          window.location.href = newUrl
        } else if (urlOrgin === process.env.REACT_APP_TEST_RU_URL) {
          const newUrl = url.replace(
            process.env.REACT_APP_TEST_RU_URL,
            process.env.REACT_APP_TEST_AE_URL
          )
          window.location.href = newUrl
        }
      }
    }
  }, [paytabs, payMethodId, gate, urlOrgin, url, payment_method_id])

  function typeChange() {
    setRf(!rf)
    setEng(!eng)

    if (rf) {
      setPayment_method_id(prodamus || paytabs)

      if (paytabs) {
        const urlWithPmid = payMethodId
          ? url.replace(/pm_id=\d+/, `pm_id=${paytabs}`)
          : location.search
            ? `${url}&pm_id=${paytabs}`
            : `${url}?pm_id=${paytabs}`
        if (urlOrgin === process.env.REACT_APP_PROD_RU_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_PROD_RU_URL,
            process.env.REACT_APP_PROD_AE_URL
          )
          window.location.href = newUrl
        } else if (urlOrgin === process.env.REACT_APP_TEST_RU_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_TEST_RU_URL,
            process.env.REACT_APP_TEST_AE_URL
          )
          window.location.href = newUrl
        }
      }
    } else {
      setPayment_method_id(cloud || tinkoff)

      if (paytabs) {
        const urlWithPmid = payMethodId
          ? url.replace(/pm_id=\d+/, `pm_id=${cloud || tinkoff}`)
          : location.search
            ? `${url}&pm_id=${cloud || tinkoff}`
            : `${url}?pm_id=${cloud || tinkoff}`
        if (urlOrgin === process.env.REACT_APP_PROD_AE_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_PROD_AE_URL,
            process.env.REACT_APP_PROD_RU_URL
          )
          window.location.href = newUrl
        } else if (urlOrgin === process.env.REACT_APP_TEST_AE_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_TEST_AE_URL,
            process.env.REACT_APP_TEST_RU_URL
          )
          window.location.href = newUrl
        }
      }
    }
  }

  function payMethodChange(e) {
    setPayment_method_id(+e.target.value)
  }

  return (
    !recStatus &&
    !disBtns &&
    !payMethodId && (
      <>
        <div className={styles.payMethod} data-testid="pay-methods-content">
          {!!payment_method_id && <p>Выберите способ оплаты</p>}
        </div>
        {(!!prodamus || !!paytabs) && (
          <div
            className={styles.customRadio}
            data-testid="custom-radio-content"
          >
            <input
              type="radio"
              name="payMethod"
              data-testid="rf"
              id="rf"
              disabled={
                gate === '1' || promoRes?.reserve_id || promoRes?.success
              }
              value={rf}
              checked={rf}
              onChange={typeChange}
            />
            <label
              data-testid="label-rf"
              className={styles.payLabel}
              htmlFor="rf"
            >
              Российская
            </label>
            {gate !== '2' && (
              <>
                <input
                  type="radio"
                  name="payMethod"
                  data-testid="inter"
                  id="inter"
                  checked={eng || gate === '1'}
                  value={eng}
                  disabled={
                    (!prodamus && !paytabs) ||
                    promoRes?.reserve_id ||
                    promoRes?.success
                  }
                  onChange={typeChange}
                />
                <label
                  data-testid="label-inter"
                  className={styles.payLabel}
                  htmlFor="inter"
                >
                  Иностранная
                </label>
              </>
            )}
          </div>
        )}
        {rf && gate !== '1' && (
          <div className={styles.rusCards} data-testid="rus-cards-content">
            {(!!cloud || !!tinkoff) && (
              <div
                className={styles.form_radio_btn}
                data-testid="rus-cards-box"
              >
                <input
                  data-testid="rf-cards"
                  id="rf-cards"
                  type="radio"
                  name="radio"
                  value={cloud || tinkoff}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={
                    cloud === payment_method_id || tinkoff === payment_method_id
                  }
                  required
                />
                <label data-testid="label-rf-cards" htmlFor="rf-cards">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}

            {!!tinkoff_sbp && (
              <div className={styles.form_radio_btn} data-testid="sbp-box">
                <input
                  data-testid="sbp"
                  id="sbp"
                  type="radio"
                  name="radio"
                  value={tinkoff_sbp}
                  disabled={promoRes?.reserve_id}
                  onChange={(e) => payMethodChange(e)}
                  required
                />
                <label data-testid="label-sbp" htmlFor="sbp">
                  <img src="/sbp.png" alt="" style={{ width: '66px' }} />
                </label>
              </div>
            )}
            {!!terpay && (
              <div className={styles.form_radio_btn} data-testid="terpay-box">
                <input
                  data-testid="terpay"
                  id="terpay"
                  type="radio"
                  name="radio"
                  value={terpay}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={terpay === payment_method_id}
                  required
                />
                <label data-testid="label-terpay" htmlFor="terpay">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}
            {!!moneta && (
              <div className={styles.form_radio_btn} data-testid="moneta-box">
                <input
                  data-testid="moneta"
                  id="moneta"
                  type="radio"
                  name="radio"
                  value={moneta}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={moneta === payment_method_id}
                  required
                />
                <label data-testid="label-moneta" htmlFor="moneta">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}
            {!!moneta_v2 && (
              <div
                className={styles.form_radio_btn}
                data-testid="moneta_v2-box"
              >
                <input
                  data-testid="moneta_v2"
                  id="moneta_v2"
                  type="radio"
                  name="radio"
                  value={moneta_v2}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={moneta_v2 === payment_method_id}
                  required
                />
                <label data-testid="label-moneta_v2" htmlFor="moneta_v2">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}
          </div>
        )}

        {eng && (
          <div className={styles.engCards} data-testid="eng-cards-content">
            {!!prodamus && (
              <div className={styles.form_radio_btn} data-testid="prodamus-box">
                <input
                  data-testid="prodamus"
                  id="prodamus"
                  type="radio"
                  name="radio"
                  value={prodamus}
                  disabled={promoRes?.reserve_id}
                  checked={prodamus === payment_method_id}
                  onChange={(e) => payMethodChange(e)}
                  required
                />
                <label data-testid="label-prodamus" htmlFor="prodamus">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIconEn}
                    alt=""
                  />
                </label>
              </div>
            )}
            {!!paytabs && (
              <div className={styles.form_radio_btn} data-testid="paytabs-box">
                <input
                  data-testid="paytabs"
                  id="paytabs"
                  type="radio"
                  name="radio"
                  value={paytabs}
                  disabled={promoRes?.reserve_id}
                  checked={paytabs === payment_method_id}
                  onChange={(e) => payMethodChange(e)}
                  required
                />
                <label data-testid="label-paytabs" htmlFor="paytabs">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIconEn}
                    alt=""
                  />
                </label>
              </div>
            )}
          </div>
        )}
      </>
    )
  )
}

export default PayMethods
